var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0"},[_c('div',{staticClass:"mb-4 text-right"},[_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"Recherche ici"},on:{"change":_vm.onSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.showModal}},[_vm._v(" Créer un agent caissier ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-modal',{attrs:{"width":_vm.width,"title":"Creer un agent caissier","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":16,"md":16}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.caissierSubmit}},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Selectionner l'agence","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'agence',
                            {
                              initialValue: _vm.agence,
                              rules: [
                                {
                                  required: true,
                                  message: 'Agence est vide!',
                                } ],
                            } ]),expression:"[\n                            'agence',\n                            {\n                              initialValue: agence,\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Agence est vide!',\n                                },\n                              ],\n                            },\n                          ]"}]},_vm._l((_vm.agences),function(agence){return _c('a-select-option',{key:agence.id,attrs:{"value":agence.id}},[_vm._v(" "+_vm._s(agence.libelle)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Selectionner le quartier","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'quartier',
                            {
                              initialValue: _vm.quartier,
                              rules: [
                                {
                                  required: true,
                                  message: 'Quartier est vide!',
                                } ],
                            } ]),expression:"[\n                            'quartier',\n                            {\n                              initialValue: quartier,\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Quartier est vide!',\n                                },\n                              ],\n                            },\n                          ]"}]},_vm._l((_vm.quartiers),function(quartier){return _c('a-select-option',{key:quartier.id,attrs:{"value":quartier.id}},[_vm._v(" "+_vm._s(quartier.libelle)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Nom du caissier","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'nom',
                            {
                              initialValue: _vm.nom,
                              rules: [
                                {
                                  required: true,
                                  message: 'Nom du caissier est vide!',
                                } ],
                            } ]),expression:"[\n                            'nom',\n                            {\n                              initialValue: nom,\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Nom du caissier est vide!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"type":"text","placeholder":"Nom agent caissier"},model:{value:(_vm.nom),callback:function ($$v) {_vm.nom=$$v},expression:"nom"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Prénom du caissier","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'prenom',
                            {
                              initialValue: _vm.prenom,
                              rules: [
                                {
                                  required: true,
                                  message: 'Prénom du caissier est vide!',
                                } ],
                            } ]),expression:"[\n                            'prenom',\n                            {\n                              initialValue: prenom,\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Prénom du caissier est vide!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"type":"text","placeholder":"Prénom agent caissier"},model:{value:(_vm.prenom),callback:function ($$v) {_vm.prenom=$$v},expression:"prenom"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Numéro de téléphone","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'numero',
                            {
                              initialValue: _vm.numero,
                              rules: [
                                {
                                  required: true,
                                  message: 'Numero est vide!',
                                } ],
                            } ]),expression:"[\n                            'numero',\n                            {\n                              initialValue: numero,\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'Numero est vide!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"type":"number","placeholder":"Numéro de téléphone"},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})],1)],1)],1)],1)],1),_c('a-col',{staticClass:"mt-4",attrs:{"span":8,"md":8}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":"Information du caissier","column":1}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.nom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Prenom"}},[_vm._v(" "+_vm._s(_vm.prenom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Numéro de téléphone"}},[_vm._v(" (+228) "+_vm._s(_vm.numero)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Mot de passe"}},[_vm._v(" "+_vm._s(_vm.password)+" ")])],1)],1)])],1)],1)],1),_c('a-table',{staticStyle:{"margin-top":"20px"},attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":true},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                    name: 'Caissier_detail',
                    params: { id: record.key },
                  }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1),(record.status == 1)?_c('a-popconfirm',{attrs:{"title":"Sûre de bloquer?"},on:{"confirm":function () { return _vm.block(record.key); }}},[_c('a-button',{attrs:{"type":"danger","size":"small"}},[_vm._v("Bloquer")])],1):_vm._e(),(record.status == 0)?_c('a-popconfirm',{attrs:{"title":"Sûre de débloquer?"},on:{"confirm":function () { return _vm.block(record.key); }}},[_c('a-button',{attrs:{"type":"success","size":"small"}},[_vm._v("Debloquer")])],1):_vm._e()],1)]}}],null,true)})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }